import GuestPostService from '../assets/pagesslider/guestpostservice.jpg';
import Guest from '../assets/services/guestpost.png';
import Outreach from '../assets/services/Outreach.png';
import ContentQuality  from '../assets/services/content-quality.png';
import CustomerSatisfaction from '../assets/services/customer-satisfaction.png';


export const Guest_Post = [
    {
        punchLine:'100% unique and high-quality guest post writing services. We have the best team of content writers',
        keypointsSection:[
            {
                heading:`Our Authority Guest Posting Service is Custom`,
                keypoints:[
                    {
                        keypoint:'We provide quality posts that build your reputation as an expert ',
                    },
                    {
                        keypoint:'Our service enables you to expand your audience',
                    },
                    {
                        keypoint:'We provide quality backlinks and improved follower growth.'
                    }
                ],
                image:GuestPostService,
            }
        ],
        Services:[
            {
                title:'Guess Post Row 1',
                services:[
                    {
                        image:Guest,
                        heading:'Top-Rated Sites',
                        paragraph:"Not every site makes our Power Squad — we choose sites using a 15-point criterion. We'll show you our work: Not only do we examine domain authority, but also organic traffic flow, site appearance, domain life, indexed pages, traffic location, etc."
                    },
                    {
                        image:Outreach,
                        heading:'Contextual Links',
                        paragraph:"Just Good Links Surrounded by Good Stuff — We realise the importance of link placement, thus we don't position your link arbitrarily in the content, sidebars, or author-bio.The mix of in-content, contextual links, niche-specific blogger sites, and compelling, relevant material gives your website visibility, organic traffic, authority, and trustworthiness with one flick."
                    },
                    {
                        image:ContentQuality,
                        heading:'Content Quality',
                        paragraph:"We don't develop stuff merely for link placement. 'Content is King' is something we live by. We won't create digital clutter. We only give authoritative guest posting services, therefore from article inspiration to creation, we keep to quality."
                    }
                ]
            },
            {
                title:'Guess Post Row 2',
                services:[
                    {
                        image:Guest,
                        heading:'Native content writers',
                        paragraph:"Combine experience, professionalism, and originality – we know high-quality guest blogging services are powered by exceptional content writers with a sense for innovation. We do!Our clients obtain guest post service from us because we've created an in-house staff of hand-picked content writers that are seasoned, niche-experts, and know how to create Google- and reader-friendly guest articles."
                    },
                    {
                        image:Outreach,
                        heading:'No PBNs',
                        paragraph:"We only publish white hat guest posts on real sites since Google hates poor SEO guest blogging. Serve Vision's guest blogging services never use shady tactics."
                    },
                    {
                        image:CustomerSatisfaction,
                        heading:'100% Customer Satisfaction',
                        paragraph:"We Guarantee Long Life of the Links We Acquire for You. Our effort doesn't finish when we write a guest article for you on a blog. Your link will be active for at least a year thanks to our guest posting service."
                    }
                ]
            }
        ],
        WhyServe:[
            {
                heading:'Why Serve Vision For Guest Post',
                paragraphs:[
                    {
                        paragraph:'Our niche-specific, influential guest posting service is expertly crafted to help your business become visible, authoritative, and well-recognized on websites with high visitor volumes.'
                    },
                    {
                        paragraph:'Unlike some companies, we don’t advertise our guest blogging services as a quick fix to improve your search engine rankings and build brand authority. We believe in pursuing the difficult path in order to provide data-driven results that are durable and advantageous for your long-term business success.'
                    },
                    {
                        paragraph:'Unlike some companies, we don’t advertise our guest blogging services as a quick fix to improve your search engine rankings and build brand authority. We believe in pursuing the difficult path in order to provide data-driven results that are durable and advantageous for your long-term business success.'
                    }
                ]
            }
        ],
        title:'Why Guest Posting Service',
        description:'Don’t let your competitors win the digital world while you struggle with outdated strategies. Let us be a partner in your success with our guest blog posting service that can turn into a fantastic flick for your digital glory with the best results.',
        WhyService:[
            {
                title:'Why Guest Row 1',
                service:[
                    {
                        heading:'Boost Your Niche Authority',
                        paragraph:'Our guest posting services collaborate with well-known bloggers in your industry-relevant field, and publishing a high-caliber piece helps to establish your brand as a market leader.'
                    },
                    {
                        heading:'Boost Your Niche Authority',
                        paragraph:'Our guest posting services collaborate with well-known bloggers in your industry-relevant field, and publishing a high-caliber piece helps to establish your brand as a market leader.'
                    },
                    {
                        heading:'Boost Your Niche Authority',
                        paragraph:'Our guest posting services collaborate with well-known bloggers in your industry-relevant field, and publishing a high-caliber piece helps to establish your brand as a market leader.'
                    }
                ]
            },
            {
                title:'Why Guest Row 2',
                service:[
                    {
                        heading:'Boost Your Niche Authority',
                        paragraph:'Our guest posting services collaborate with well-known bloggers in your industry-relevant field, and publishing a high-caliber piece helps to establish your brand as a market leader.'
                    },
                    {
                        heading:'Boost Your Niche Authority',
                        paragraph:'Our guest posting services collaborate with well-known bloggers in your industry-relevant field, and publishing a high-caliber piece helps to establish your brand as a market leader.'
                    },
                    {
                        heading:'Boost Your Niche Authority',
                        paragraph:'Our guest posting services collaborate with well-known bloggers in your industry-relevant field, and publishing a high-caliber piece helps to establish your brand as a market leader.'
                    }
                ]
            }
        ],
        BuyService:[
            {
                heading:'Buy Guest Posts Now',
                paragraph:'Place Your Order and Save 20% OFF on First Job!'
            }
        ],
        Frequently:[
                {
                    id:'0',
                    ques:'What is Guest Post Service ?',
                    answer:'A guest post is content you write on another website that links to yours. This increases your site’s awareness, qualified visitors, authority, credibility, and recognition'
                },
                {
                    id:'1',
                    ques:'Where Can I Guest Post ?',
                    answer:'Most blogs accept guest posts, but you need niche-relevant sites with excellent traffic and authority. Guest-posting websites have a ‘Write for Us’ page containing the blogger’s requirements.'
                }
        ]
    }
]

export const PPC = [
    {
        punchLine:'100% unique and high-quality guest post writing services. We have the best team of content writers',
        keypointsSection:[
            {
                heading:`Our Authority Guest Posting Service is Custom`,
                keypoints:[
                    {
                        keypoint:'We provide quality posts that build your reputation as an expert ',
                    },
                    {
                        keypoint:'Our service enables you to expand your audience',
                    },
                    {
                        keypoint:'We provide quality backlinks and improved follower growth.'
                    }
                ],
                image:GuestPostService,
            }
        ],
        Services:[
            {
                title:'Guess Post Row 1',
                services:[
                    {
                        image:Guest,
                        heading:'Top-Rated Sites',
                        paragraph:"Not every site makes our Power Squad — we choose sites using a 15-point criterion. We'll show you our work: Not only do we examine domain authority, but also organic traffic flow, site appearance, domain life, indexed pages, traffic location, etc."
                    },
                    {
                        image:Outreach,
                        heading:'Contextual Links',
                        paragraph:"Just Good Links Surrounded by Good Stuff — We realise the importance of link placement, thus we don't position your link arbitrarily in the content, sidebars, or author-bio.The mix of in-content, contextual links, niche-specific blogger sites, and compelling, relevant material gives your website visibility, organic traffic, authority, and trustworthiness with one flick."
                    },
                    {
                        image:ContentQuality,
                        heading:'Content Quality',
                        paragraph:"We don't develop stuff merely for link placement. 'Content is King' is something we live by. We won't create digital clutter. We only give authoritative guest posting services, therefore from article inspiration to creation, we keep to quality."
                    }
                ]
            },
            {
                title:'Guess Post Row 2',
                services:[
                    {
                        image:Guest,
                        heading:'Native content writers',
                        paragraph:"Combine experience, professionalism, and originality – we know high-quality guest blogging services are powered by exceptional content writers with a sense for innovation. We do!Our clients obtain guest post service from us because we've created an in-house staff of hand-picked content writers that are seasoned, niche-experts, and know how to create Google- and reader-friendly guest articles."
                    },
                    {
                        image:Outreach,
                        heading:'No PBNs',
                        paragraph:"We only publish white hat guest posts on real sites since Google hates poor SEO guest blogging. Serve Vision's guest blogging services never use shady tactics."
                    },
                    {
                        image:CustomerSatisfaction,
                        heading:'100% Customer Satisfaction',
                        paragraph:"We Guarantee Long Life of the Links We Acquire for You. Our effort doesn't finish when we write a guest article for you on a blog. Your link will be active for at least a year thanks to our guest posting service."
                    }
                ]
            }
        ],
        WhyServe:[
            {
                heading:'Why Serve Vision For Guest Post',
                paragraphs:[
                    {
                        paragraph:'Our niche-specific, influential guest posting service is expertly crafted to help your business become visible, authoritative, and well-recognized on websites with high visitor volumes.'
                    },
                    {
                        paragraph:'Unlike some companies, we don’t advertise our guest blogging services as a quick fix to improve your search engine rankings and build brand authority. We believe in pursuing the difficult path in order to provide data-driven results that are durable and advantageous for your long-term business success.'
                    },
                    {
                        paragraph:'Unlike some companies, we don’t advertise our guest blogging services as a quick fix to improve your search engine rankings and build brand authority. We believe in pursuing the difficult path in order to provide data-driven results that are durable and advantageous for your long-term business success.'
                    }
                ]
            }
        ],
        title:'Why Guest Posting Service',
        description:'Don’t let your competitors win the digital world while you struggle with outdated strategies. Let us be a partner in your success with our guest blog posting service that can turn into a fantastic flick for your digital glory with the best results.',
        WhyService:[
            {
                title:'Why Guest Row 1',
                service:[
                    {
                        heading:'Boost Your Niche Authority',
                        paragraph:'Our guest posting services collaborate with well-known bloggers in your industry-relevant field, and publishing a high-caliber piece helps to establish your brand as a market leader.'
                    },
                    {
                        heading:'Boost Your Niche Authority',
                        paragraph:'Our guest posting services collaborate with well-known bloggers in your industry-relevant field, and publishing a high-caliber piece helps to establish your brand as a market leader.'
                    },
                    {
                        heading:'Boost Your Niche Authority',
                        paragraph:'Our guest posting services collaborate with well-known bloggers in your industry-relevant field, and publishing a high-caliber piece helps to establish your brand as a market leader.'
                    }
                ]
            },
            {
                title:'Why Guest Row 2',
                service:[
                    {
                        heading:'Boost Your Niche Authority',
                        paragraph:'Our guest posting services collaborate with well-known bloggers in your industry-relevant field, and publishing a high-caliber piece helps to establish your brand as a market leader.'
                    },
                    {
                        heading:'Boost Your Niche Authority',
                        paragraph:'Our guest posting services collaborate with well-known bloggers in your industry-relevant field, and publishing a high-caliber piece helps to establish your brand as a market leader.'
                    },
                    {
                        heading:'Boost Your Niche Authority',
                        paragraph:'Our guest posting services collaborate with well-known bloggers in your industry-relevant field, and publishing a high-caliber piece helps to establish your brand as a market leader.'
                    }
                ]
            }
        ],
        BuyService:[
            {
                heading:'Buy Guest Posts Now',
                paragraph:'Place Your Order and Save 20% OFF on First Job!'
            }
        ],
        Frequently:[
                {
                    id:'0',
                    ques:'What is Guest Post Service ?',
                    answer:'A guest post is content you write on another website that links to yours. This increases your site’s awareness, qualified visitors, authority, credibility, and recognition'
                },
                {
                    id:'1',
                    ques:'Where Can I Guest Post ?',
                    answer:'Most blogs accept guest posts, but you need niche-relevant sites with excellent traffic and authority. Guest-posting websites have a ‘Write for Us’ page containing the blogger’s requirements.'
                }
        ]
    }
]
export const Content = [
    {
        punchLine:'100% unique and high-quality guest post writing services. We have the best team of content writers',
        keypointsSection:[
            {
                heading:`Our Authority Guest Posting Service is Custom`,
                keypoints:[
                    {
                        keypoint:'We provide quality posts that build your reputation as an expert ',
                    },
                    {
                        keypoint:'Our service enables you to expand your audience',
                    },
                    {
                        keypoint:'We provide quality backlinks and improved follower growth.'
                    }
                ],
                image:GuestPostService,
            }
        ],
        Services:[
            {
                title:'Guess Post Row 1',
                services:[
                    {
                        image:Guest,
                        heading:'Top-Rated Sites',
                        paragraph:"Not every site makes our Power Squad — we choose sites using a 15-point criterion. We'll show you our work: Not only do we examine domain authority, but also organic traffic flow, site appearance, domain life, indexed pages, traffic location, etc."
                    },
                    {
                        image:Outreach,
                        heading:'Contextual Links',
                        paragraph:"Just Good Links Surrounded by Good Stuff — We realise the importance of link placement, thus we don't position your link arbitrarily in the content, sidebars, or author-bio.The mix of in-content, contextual links, niche-specific blogger sites, and compelling, relevant material gives your website visibility, organic traffic, authority, and trustworthiness with one flick."
                    },
                    {
                        image:ContentQuality,
                        heading:'Content Quality',
                        paragraph:"We don't develop stuff merely for link placement. 'Content is King' is something we live by. We won't create digital clutter. We only give authoritative guest posting services, therefore from article inspiration to creation, we keep to quality."
                    }
                ]
            },
            {
                title:'Guess Post Row 2',
                services:[
                    {
                        image:Guest,
                        heading:'Native content writers',
                        paragraph:"Combine experience, professionalism, and originality – we know high-quality guest blogging services are powered by exceptional content writers with a sense for innovation. We do!Our clients obtain guest post service from us because we've created an in-house staff of hand-picked content writers that are seasoned, niche-experts, and know how to create Google- and reader-friendly guest articles."
                    },
                    {
                        image:Outreach,
                        heading:'No PBNs',
                        paragraph:"We only publish white hat guest posts on real sites since Google hates poor SEO guest blogging. Serve Vision's guest blogging services never use shady tactics."
                    },
                    {
                        image:CustomerSatisfaction,
                        heading:'100% Customer Satisfaction',
                        paragraph:"We Guarantee Long Life of the Links We Acquire for You. Our effort doesn't finish when we write a guest article for you on a blog. Your link will be active for at least a year thanks to our guest posting service."
                    }
                ]
            }
        ],
        WhyServe:[
            {
                heading:'Why Serve Vision For Guest Post',
                paragraphs:[
                    {
                        paragraph:'Our niche-specific, influential guest posting service is expertly crafted to help your business become visible, authoritative, and well-recognized on websites with high visitor volumes.'
                    },
                    {
                        paragraph:'Unlike some companies, we don’t advertise our guest blogging services as a quick fix to improve your search engine rankings and build brand authority. We believe in pursuing the difficult path in order to provide data-driven results that are durable and advantageous for your long-term business success.'
                    },
                    {
                        paragraph:'Unlike some companies, we don’t advertise our guest blogging services as a quick fix to improve your search engine rankings and build brand authority. We believe in pursuing the difficult path in order to provide data-driven results that are durable and advantageous for your long-term business success.'
                    }
                ]
            }
        ],
        title:'Why Guest Posting Service',
        description:'Don’t let your competitors win the digital world while you struggle with outdated strategies. Let us be a partner in your success with our guest blog posting service that can turn into a fantastic flick for your digital glory with the best results.',
        WhyService:[
            {
                title:'Why Guest Row 1',
                service:[
                    {
                        heading:'Boost Your Niche Authority',
                        paragraph:'Our guest posting services collaborate with well-known bloggers in your industry-relevant field, and publishing a high-caliber piece helps to establish your brand as a market leader.'
                    },
                    {
                        heading:'Boost Your Niche Authority',
                        paragraph:'Our guest posting services collaborate with well-known bloggers in your industry-relevant field, and publishing a high-caliber piece helps to establish your brand as a market leader.'
                    },
                    {
                        heading:'Boost Your Niche Authority',
                        paragraph:'Our guest posting services collaborate with well-known bloggers in your industry-relevant field, and publishing a high-caliber piece helps to establish your brand as a market leader.'
                    }
                ]
            },
            {
                title:'Why Guest Row 2',
                service:[
                    {
                        heading:'Boost Your Niche Authority',
                        paragraph:'Our guest posting services collaborate with well-known bloggers in your industry-relevant field, and publishing a high-caliber piece helps to establish your brand as a market leader.'
                    },
                    {
                        heading:'Boost Your Niche Authority',
                        paragraph:'Our guest posting services collaborate with well-known bloggers in your industry-relevant field, and publishing a high-caliber piece helps to establish your brand as a market leader.'
                    },
                    {
                        heading:'Boost Your Niche Authority',
                        paragraph:'Our guest posting services collaborate with well-known bloggers in your industry-relevant field, and publishing a high-caliber piece helps to establish your brand as a market leader.'
                    }
                ]
            }
        ],
        BuyService:[
            {
                heading:'Buy Guest Posts Now',
                paragraph:'Place Your Order and Save 20% OFF on First Job!'
            }
        ],
        Frequently:[
                {
                    id:'0',
                    ques:'What is Guest Post Service ?',
                    answer:'A guest post is content you write on another website that links to yours. This increases your site’s awareness, qualified visitors, authority, credibility, and recognition'
                },
                {
                    id:'1',
                    ques:'Where Can I Guest Post ?',
                    answer:'Most blogs accept guest posts, but you need niche-relevant sites with excellent traffic and authority. Guest-posting websites have a ‘Write for Us’ page containing the blogger’s requirements.'
                }
        ]
    }
]
export const Music = [
    {
        punchLine:'100% unique and high-quality guest post writing services. We have the best team of content writers',
        keypointsSection:[
            {
                heading:`Our Authority Guest Posting Service is Custom`,
                keypoints:[
                    {
                        keypoint:'We provide quality posts that build your reputation as an expert ',
                    },
                    {
                        keypoint:'Our service enables you to expand your audience',
                    },
                    {
                        keypoint:'We provide quality backlinks and improved follower growth.'
                    }
                ],
                image:GuestPostService,
            }
        ],
        Services:[
            {
                title:'Guess Post Row 1',
                services:[
                    {
                        image:Guest,
                        heading:'Top-Rated Sites',
                        paragraph:"Not every site makes our Power Squad — we choose sites using a 15-point criterion. We'll show you our work: Not only do we examine domain authority, but also organic traffic flow, site appearance, domain life, indexed pages, traffic location, etc."
                    },
                    {
                        image:Outreach,
                        heading:'Contextual Links',
                        paragraph:"Just Good Links Surrounded by Good Stuff — We realise the importance of link placement, thus we don't position your link arbitrarily in the content, sidebars, or author-bio.The mix of in-content, contextual links, niche-specific blogger sites, and compelling, relevant material gives your website visibility, organic traffic, authority, and trustworthiness with one flick."
                    },
                    {
                        image:ContentQuality,
                        heading:'Content Quality',
                        paragraph:"We don't develop stuff merely for link placement. 'Content is King' is something we live by. We won't create digital clutter. We only give authoritative guest posting services, therefore from article inspiration to creation, we keep to quality."
                    }
                ]
            },
            {
                title:'Guess Post Row 2',
                services:[
                    {
                        image:Guest,
                        heading:'Native content writers',
                        paragraph:"Combine experience, professionalism, and originality – we know high-quality guest blogging services are powered by exceptional content writers with a sense for innovation. We do!Our clients obtain guest post service from us because we've created an in-house staff of hand-picked content writers that are seasoned, niche-experts, and know how to create Google- and reader-friendly guest articles."
                    },
                    {
                        image:Outreach,
                        heading:'No PBNs',
                        paragraph:"We only publish white hat guest posts on real sites since Google hates poor SEO guest blogging. Serve Vision's guest blogging services never use shady tactics."
                    },
                    {
                        image:CustomerSatisfaction,
                        heading:'100% Customer Satisfaction',
                        paragraph:"We Guarantee Long Life of the Links We Acquire for You. Our effort doesn't finish when we write a guest article for you on a blog. Your link will be active for at least a year thanks to our guest posting service."
                    }
                ]
            }
        ],
        WhyServe:[
            {
                heading:'Why Serve Vision For Guest Post',
                paragraphs:[
                    {
                        paragraph:'Our niche-specific, influential guest posting service is expertly crafted to help your business become visible, authoritative, and well-recognized on websites with high visitor volumes.'
                    },
                    {
                        paragraph:'Unlike some companies, we don’t advertise our guest blogging services as a quick fix to improve your search engine rankings and build brand authority. We believe in pursuing the difficult path in order to provide data-driven results that are durable and advantageous for your long-term business success.'
                    },
                    {
                        paragraph:'Unlike some companies, we don’t advertise our guest blogging services as a quick fix to improve your search engine rankings and build brand authority. We believe in pursuing the difficult path in order to provide data-driven results that are durable and advantageous for your long-term business success.'
                    }
                ]
            }
        ],
        title:'Why Guest Posting Service',
        description:'Don’t let your competitors win the digital world while you struggle with outdated strategies. Let us be a partner in your success with our guest blog posting service that can turn into a fantastic flick for your digital glory with the best results.',
        WhyService:[
            {
                title:'Why Guest Row 1',
                service:[
                    {
                        heading:'Boost Your Niche Authority',
                        paragraph:'Our guest posting services collaborate with well-known bloggers in your industry-relevant field, and publishing a high-caliber piece helps to establish your brand as a market leader.'
                    },
                    {
                        heading:'Boost Your Niche Authority',
                        paragraph:'Our guest posting services collaborate with well-known bloggers in your industry-relevant field, and publishing a high-caliber piece helps to establish your brand as a market leader.'
                    },
                    {
                        heading:'Boost Your Niche Authority',
                        paragraph:'Our guest posting services collaborate with well-known bloggers in your industry-relevant field, and publishing a high-caliber piece helps to establish your brand as a market leader.'
                    }
                ]
            },
            {
                title:'Why Guest Row 2',
                service:[
                    {
                        heading:'Boost Your Niche Authority',
                        paragraph:'Our guest posting services collaborate with well-known bloggers in your industry-relevant field, and publishing a high-caliber piece helps to establish your brand as a market leader.'
                    },
                    {
                        heading:'Boost Your Niche Authority',
                        paragraph:'Our guest posting services collaborate with well-known bloggers in your industry-relevant field, and publishing a high-caliber piece helps to establish your brand as a market leader.'
                    },
                    {
                        heading:'Boost Your Niche Authority',
                        paragraph:'Our guest posting services collaborate with well-known bloggers in your industry-relevant field, and publishing a high-caliber piece helps to establish your brand as a market leader.'
                    }
                ]
            }
        ],
        BuyService:[
            {
                heading:'Buy Guest Posts Now',
                paragraph:'Place Your Order and Save 20% OFF on First Job!'
            }
        ],
        Frequently:[
                {
                    id:'0',
                    ques:'What is Guest Post Service ?',
                    answer:'A guest post is content you write on another website that links to yours. This increases your site’s awareness, qualified visitors, authority, credibility, and recognition'
                },
                {
                    id:'1',
                    ques:'Where Can I Guest Post ?',
                    answer:'Most blogs accept guest posts, but you need niche-relevant sites with excellent traffic and authority. Guest-posting websites have a ‘Write for Us’ page containing the blogger’s requirements.'
                }
        ]
    }
]
export const OutreachP = [
    {
        punchLine:'100% unique and high-quality guest post writing services. We have the best team of content writers',
        keypointsSection:[
            {
                heading:`Our Authority Guest Posting Service is Custom`,
                keypoints:[
                    {
                        keypoint:'We provide quality posts that build your reputation as an expert ',
                    },
                    {
                        keypoint:'Our service enables you to expand your audience',
                    },
                    {
                        keypoint:'We provide quality backlinks and improved follower growth.'
                    }
                ],
                image:GuestPostService,
            }
        ],
        Services:[
            {
                title:'Guess Post Row 1',
                services:[
                    {
                        image:Guest,
                        heading:'Top-Rated Sites',
                        paragraph:"Not every site makes our Power Squad — we choose sites using a 15-point criterion. We'll show you our work: Not only do we examine domain authority, but also organic traffic flow, site appearance, domain life, indexed pages, traffic location, etc."
                    },
                    {
                        image:Outreach,
                        heading:'Contextual Links',
                        paragraph:"Just Good Links Surrounded by Good Stuff — We realise the importance of link placement, thus we don't position your link arbitrarily in the content, sidebars, or author-bio.The mix of in-content, contextual links, niche-specific blogger sites, and compelling, relevant material gives your website visibility, organic traffic, authority, and trustworthiness with one flick."
                    },
                    {
                        image:ContentQuality,
                        heading:'Content Quality',
                        paragraph:"We don't develop stuff merely for link placement. 'Content is King' is something we live by. We won't create digital clutter. We only give authoritative guest posting services, therefore from article inspiration to creation, we keep to quality."
                    }
                ]
            },
            {
                title:'Guess Post Row 2',
                services:[
                    {
                        image:Guest,
                        heading:'Native content writers',
                        paragraph:"Combine experience, professionalism, and originality – we know high-quality guest blogging services are powered by exceptional content writers with a sense for innovation. We do!Our clients obtain guest post service from us because we've created an in-house staff of hand-picked content writers that are seasoned, niche-experts, and know how to create Google- and reader-friendly guest articles."
                    },
                    {
                        image:Outreach,
                        heading:'No PBNs',
                        paragraph:"We only publish white hat guest posts on real sites since Google hates poor SEO guest blogging. Serve Vision's guest blogging services never use shady tactics."
                    },
                    {
                        image:CustomerSatisfaction,
                        heading:'100% Customer Satisfaction',
                        paragraph:"We Guarantee Long Life of the Links We Acquire for You. Our effort doesn't finish when we write a guest article for you on a blog. Your link will be active for at least a year thanks to our guest posting service."
                    }
                ]
            }
        ],
        WhyServe:[
            {
                heading:'Why Serve Vision For Guest Post',
                paragraphs:[
                    {
                        paragraph:'Our niche-specific, influential guest posting service is expertly crafted to help your business become visible, authoritative, and well-recognized on websites with high visitor volumes.'
                    },
                    {
                        paragraph:'Unlike some companies, we don’t advertise our guest blogging services as a quick fix to improve your search engine rankings and build brand authority. We believe in pursuing the difficult path in order to provide data-driven results that are durable and advantageous for your long-term business success.'
                    },
                    {
                        paragraph:'Unlike some companies, we don’t advertise our guest blogging services as a quick fix to improve your search engine rankings and build brand authority. We believe in pursuing the difficult path in order to provide data-driven results that are durable and advantageous for your long-term business success.'
                    }
                ]
            }
        ],
        title:'Why Guest Posting Service',
        description:'Don’t let your competitors win the digital world while you struggle with outdated strategies. Let us be a partner in your success with our guest blog posting service that can turn into a fantastic flick for your digital glory with the best results.',
        WhyService:[
            {
                title:'Why Guest Row 1',
                service:[
                    {
                        heading:'Boost Your Niche Authority',
                        paragraph:'Our guest posting services collaborate with well-known bloggers in your industry-relevant field, and publishing a high-caliber piece helps to establish your brand as a market leader.'
                    },
                    {
                        heading:'Boost Your Niche Authority',
                        paragraph:'Our guest posting services collaborate with well-known bloggers in your industry-relevant field, and publishing a high-caliber piece helps to establish your brand as a market leader.'
                    },
                    {
                        heading:'Boost Your Niche Authority',
                        paragraph:'Our guest posting services collaborate with well-known bloggers in your industry-relevant field, and publishing a high-caliber piece helps to establish your brand as a market leader.'
                    }
                ]
            },
            {
                title:'Why Guest Row 2',
                service:[
                    {
                        heading:'Boost Your Niche Authority',
                        paragraph:'Our guest posting services collaborate with well-known bloggers in your industry-relevant field, and publishing a high-caliber piece helps to establish your brand as a market leader.'
                    },
                    {
                        heading:'Boost Your Niche Authority',
                        paragraph:'Our guest posting services collaborate with well-known bloggers in your industry-relevant field, and publishing a high-caliber piece helps to establish your brand as a market leader.'
                    },
                    {
                        heading:'Boost Your Niche Authority',
                        paragraph:'Our guest posting services collaborate with well-known bloggers in your industry-relevant field, and publishing a high-caliber piece helps to establish your brand as a market leader.'
                    }
                ]
            }
        ],
        BuyService:[
            {
                heading:'Buy Guest Posts Now',
                paragraph:'Place Your Order and Save 20% OFF on First Job!'
            }
        ],
        Frequently:[
                {
                    id:'0',
                    ques:'What is Guest Post Service ?',
                    answer:'A guest post is content you write on another website that links to yours. This increases your site’s awareness, qualified visitors, authority, credibility, and recognition'
                },
                {
                    id:'1',
                    ques:'Where Can I Guest Post ?',
                    answer:'Most blogs accept guest posts, but you need niche-relevant sites with excellent traffic and authority. Guest-posting websites have a ‘Write for Us’ page containing the blogger’s requirements.'
                }
        ]
    }
]
export const SEO = [
    {
        punchLine:'100% unique and high-quality guest post writing services. We have the best team of content writers',
        keypointsSection:[
            {
                heading:`Our Authority Guest Posting Service is Custom`,
                keypoints:[
                    {
                        keypoint:'We provide quality posts that build your reputation as an expert ',
                    },
                    {
                        keypoint:'Our service enables you to expand your audience',
                    },
                    {
                        keypoint:'We provide quality backlinks and improved follower growth.'
                    }
                ],
                image:GuestPostService,
            }
        ],
        Services:[
            {
                title:'Guess Post Row 1',
                services:[
                    {
                        image:Guest,
                        heading:'Top-Rated Sites',
                        paragraph:"Not every site makes our Power Squad — we choose sites using a 15-point criterion. We'll show you our work: Not only do we examine domain authority, but also organic traffic flow, site appearance, domain life, indexed pages, traffic location, etc."
                    },
                    {
                        image:Outreach,
                        heading:'Contextual Links',
                        paragraph:"Just Good Links Surrounded by Good Stuff — We realise the importance of link placement, thus we don't position your link arbitrarily in the content, sidebars, or author-bio.The mix of in-content, contextual links, niche-specific blogger sites, and compelling, relevant material gives your website visibility, organic traffic, authority, and trustworthiness with one flick."
                    },
                    {
                        image:ContentQuality,
                        heading:'Content Quality',
                        paragraph:"We don't develop stuff merely for link placement. 'Content is King' is something we live by. We won't create digital clutter. We only give authoritative guest posting services, therefore from article inspiration to creation, we keep to quality."
                    }
                ]
            },
            {
                title:'Guess Post Row 2',
                services:[
                    {
                        image:Guest,
                        heading:'Native content writers',
                        paragraph:"Combine experience, professionalism, and originality – we know high-quality guest blogging services are powered by exceptional content writers with a sense for innovation. We do!Our clients obtain guest post service from us because we've created an in-house staff of hand-picked content writers that are seasoned, niche-experts, and know how to create Google- and reader-friendly guest articles."
                    },
                    {
                        image:Outreach,
                        heading:'No PBNs',
                        paragraph:"We only publish white hat guest posts on real sites since Google hates poor SEO guest blogging. Serve Vision's guest blogging services never use shady tactics."
                    },
                    {
                        image:CustomerSatisfaction,
                        heading:'100% Customer Satisfaction',
                        paragraph:"We Guarantee Long Life of the Links We Acquire for You. Our effort doesn't finish when we write a guest article for you on a blog. Your link will be active for at least a year thanks to our guest posting service."
                    }
                ]
            }
        ],
        WhyServe:[
            {
                heading:'Why Serve Vision For Guest Post',
                paragraphs:[
                    {
                        paragraph:'Our niche-specific, influential guest posting service is expertly crafted to help your business become visible, authoritative, and well-recognized on websites with high visitor volumes.'
                    },
                    {
                        paragraph:'Unlike some companies, we don’t advertise our guest blogging services as a quick fix to improve your search engine rankings and build brand authority. We believe in pursuing the difficult path in order to provide data-driven results that are durable and advantageous for your long-term business success.'
                    },
                    {
                        paragraph:'Unlike some companies, we don’t advertise our guest blogging services as a quick fix to improve your search engine rankings and build brand authority. We believe in pursuing the difficult path in order to provide data-driven results that are durable and advantageous for your long-term business success.'
                    }
                ]
            }
        ],
        title:'Why Guest Posting Service',
        description:'Don’t let your competitors win the digital world while you struggle with outdated strategies. Let us be a partner in your success with our guest blog posting service that can turn into a fantastic flick for your digital glory with the best results.',
        WhyService:[
            {
                title:'Why Guest Row 1',
                service:[
                    {
                        heading:'Boost Your Niche Authority',
                        paragraph:'Our guest posting services collaborate with well-known bloggers in your industry-relevant field, and publishing a high-caliber piece helps to establish your brand as a market leader.'
                    },
                    {
                        heading:'Boost Your Niche Authority',
                        paragraph:'Our guest posting services collaborate with well-known bloggers in your industry-relevant field, and publishing a high-caliber piece helps to establish your brand as a market leader.'
                    },
                    {
                        heading:'Boost Your Niche Authority',
                        paragraph:'Our guest posting services collaborate with well-known bloggers in your industry-relevant field, and publishing a high-caliber piece helps to establish your brand as a market leader.'
                    }
                ]
            },
            {
                title:'Why Guest Row 2',
                service:[
                    {
                        heading:'Boost Your Niche Authority',
                        paragraph:'Our guest posting services collaborate with well-known bloggers in your industry-relevant field, and publishing a high-caliber piece helps to establish your brand as a market leader.'
                    },
                    {
                        heading:'Boost Your Niche Authority',
                        paragraph:'Our guest posting services collaborate with well-known bloggers in your industry-relevant field, and publishing a high-caliber piece helps to establish your brand as a market leader.'
                    },
                    {
                        heading:'Boost Your Niche Authority',
                        paragraph:'Our guest posting services collaborate with well-known bloggers in your industry-relevant field, and publishing a high-caliber piece helps to establish your brand as a market leader.'
                    }
                ]
            }
        ],
        BuyService:[
            {
                heading:'Buy Guest Posts Now',
                paragraph:'Place Your Order and Save 20% OFF on First Job!'
            }
        ],
        Frequently:[
                {
                    id:'0',
                    ques:'What is Guest Post Service ?',
                    answer:'A guest post is content you write on another website that links to yours. This increases your site’s awareness, qualified visitors, authority, credibility, and recognition'
                },
                {
                    id:'1',
                    ques:'Where Can I Guest Post ?',
                    answer:'Most blogs accept guest posts, but you need niche-relevant sites with excellent traffic and authority. Guest-posting websites have a ‘Write for Us’ page containing the blogger’s requirements.'
                }
        ]
    }
]
export const SMM = [
    {
        punchLine:'100% unique and high-quality guest post writing services. We have the best team of content writers',
        keypointsSection:[
            {
                heading:`Our Authority Guest Posting Service is Custom`,
                keypoints:[
                    {
                        keypoint:'We provide quality posts that build your reputation as an expert ',
                    },
                    {
                        keypoint:'Our service enables you to expand your audience',
                    },
                    {
                        keypoint:'We provide quality backlinks and improved follower growth.'
                    }
                ],
                image:GuestPostService,
            }
        ],
        Services:[
            {
                title:'Guess Post Row 1',
                services:[
                    {
                        image:Guest,
                        heading:'Top-Rated Sites',
                        paragraph:"Not every site makes our Power Squad — we choose sites using a 15-point criterion. We'll show you our work: Not only do we examine domain authority, but also organic traffic flow, site appearance, domain life, indexed pages, traffic location, etc."
                    },
                    {
                        image:Outreach,
                        heading:'Contextual Links',
                        paragraph:"Just Good Links Surrounded by Good Stuff — We realise the importance of link placement, thus we don't position your link arbitrarily in the content, sidebars, or author-bio.The mix of in-content, contextual links, niche-specific blogger sites, and compelling, relevant material gives your website visibility, organic traffic, authority, and trustworthiness with one flick."
                    },
                    {
                        image:ContentQuality,
                        heading:'Content Quality',
                        paragraph:"We don't develop stuff merely for link placement. 'Content is King' is something we live by. We won't create digital clutter. We only give authoritative guest posting services, therefore from article inspiration to creation, we keep to quality."
                    }
                ]
            },
            {
                title:'Guess Post Row 2',
                services:[
                    {
                        image:Guest,
                        heading:'Native content writers',
                        paragraph:"Combine experience, professionalism, and originality – we know high-quality guest blogging services are powered by exceptional content writers with a sense for innovation. We do!Our clients obtain guest post service from us because we've created an in-house staff of hand-picked content writers that are seasoned, niche-experts, and know how to create Google- and reader-friendly guest articles."
                    },
                    {
                        image:Outreach,
                        heading:'No PBNs',
                        paragraph:"We only publish white hat guest posts on real sites since Google hates poor SEO guest blogging. Serve Vision's guest blogging services never use shady tactics."
                    },
                    {
                        image:CustomerSatisfaction,
                        heading:'100% Customer Satisfaction',
                        paragraph:"We Guarantee Long Life of the Links We Acquire for You. Our effort doesn't finish when we write a guest article for you on a blog. Your link will be active for at least a year thanks to our guest posting service."
                    }
                ]
            }
        ],
        WhyServe:[
            {
                heading:'Why Serve Vision For Guest Post',
                paragraphs:[
                    {
                        paragraph:'Our niche-specific, influential guest posting service is expertly crafted to help your business become visible, authoritative, and well-recognized on websites with high visitor volumes.'
                    },
                    {
                        paragraph:'Unlike some companies, we don’t advertise our guest blogging services as a quick fix to improve your search engine rankings and build brand authority. We believe in pursuing the difficult path in order to provide data-driven results that are durable and advantageous for your long-term business success.'
                    },
                    {
                        paragraph:'Unlike some companies, we don’t advertise our guest blogging services as a quick fix to improve your search engine rankings and build brand authority. We believe in pursuing the difficult path in order to provide data-driven results that are durable and advantageous for your long-term business success.'
                    }
                ]
            }
        ],
        title:'Why Guest Posting Service',
        description:'Don’t let your competitors win the digital world while you struggle with outdated strategies. Let us be a partner in your success with our guest blog posting service that can turn into a fantastic flick for your digital glory with the best results.',
        WhyService:[
            {
                title:'Why Guest Row 1',
                service:[
                    {
                        heading:'Boost Your Niche Authority',
                        paragraph:'Our guest posting services collaborate with well-known bloggers in your industry-relevant field, and publishing a high-caliber piece helps to establish your brand as a market leader.'
                    },
                    {
                        heading:'Boost Your Niche Authority',
                        paragraph:'Our guest posting services collaborate with well-known bloggers in your industry-relevant field, and publishing a high-caliber piece helps to establish your brand as a market leader.'
                    },
                    {
                        heading:'Boost Your Niche Authority',
                        paragraph:'Our guest posting services collaborate with well-known bloggers in your industry-relevant field, and publishing a high-caliber piece helps to establish your brand as a market leader.'
                    }
                ]
            },
            {
                title:'Why Guest Row 2',
                service:[
                    {
                        heading:'Boost Your Niche Authority',
                        paragraph:'Our guest posting services collaborate with well-known bloggers in your industry-relevant field, and publishing a high-caliber piece helps to establish your brand as a market leader.'
                    },
                    {
                        heading:'Boost Your Niche Authority',
                        paragraph:'Our guest posting services collaborate with well-known bloggers in your industry-relevant field, and publishing a high-caliber piece helps to establish your brand as a market leader.'
                    },
                    {
                        heading:'Boost Your Niche Authority',
                        paragraph:'Our guest posting services collaborate with well-known bloggers in your industry-relevant field, and publishing a high-caliber piece helps to establish your brand as a market leader.'
                    }
                ]
            }
        ],
        BuyService:[
            {
                heading:'Buy Guest Posts Now',
                paragraph:'Place Your Order and Save 20% OFF on First Job!'
            }
        ],
        Frequently:[
                {
                    id:'0',
                    ques:'What is Guest Post Service ?',
                    answer:'A guest post is content you write on another website that links to yours. This increases your site’s awareness, qualified visitors, authority, credibility, and recognition'
                },
                {
                    id:'1',
                    ques:'Where Can I Guest Post ?',
                    answer:'Most blogs accept guest posts, but you need niche-relevant sites with excellent traffic and authority. Guest-posting websites have a ‘Write for Us’ page containing the blogger’s requirements.'
                }
        ]
    }
]
export const Video = [
    {
        punchLine:'100% unique and high-quality guest post writing services. We have the best team of content writers',
        keypointsSection:[
            {
                heading:`Our Authority Guest Posting Service is Custom`,
                keypoints:[
                    {
                        keypoint:'We provide quality posts that build your reputation as an expert ',
                    },
                    {
                        keypoint:'Our service enables you to expand your audience',
                    },
                    {
                        keypoint:'We provide quality backlinks and improved follower growth.'
                    }
                ],
                image:GuestPostService,
            }
        ],
        Services:[
            {
                title:'Guess Post Row 1',
                services:[
                    {
                        image:Guest,
                        heading:'Top-Rated Sites',
                        paragraph:"Not every site makes our Power Squad — we choose sites using a 15-point criterion. We'll show you our work: Not only do we examine domain authority, but also organic traffic flow, site appearance, domain life, indexed pages, traffic location, etc."
                    },
                    {
                        image:Outreach,
                        heading:'Contextual Links',
                        paragraph:"Just Good Links Surrounded by Good Stuff — We realise the importance of link placement, thus we don't position your link arbitrarily in the content, sidebars, or author-bio.The mix of in-content, contextual links, niche-specific blogger sites, and compelling, relevant material gives your website visibility, organic traffic, authority, and trustworthiness with one flick."
                    },
                    {
                        image:ContentQuality,
                        heading:'Content Quality',
                        paragraph:"We don't develop stuff merely for link placement. 'Content is King' is something we live by. We won't create digital clutter. We only give authoritative guest posting services, therefore from article inspiration to creation, we keep to quality."
                    }
                ]
            },
            {
                title:'Guess Post Row 2',
                services:[
                    {
                        image:Guest,
                        heading:'Native content writers',
                        paragraph:"Combine experience, professionalism, and originality – we know high-quality guest blogging services are powered by exceptional content writers with a sense for innovation. We do!Our clients obtain guest post service from us because we've created an in-house staff of hand-picked content writers that are seasoned, niche-experts, and know how to create Google- and reader-friendly guest articles."
                    },
                    {
                        image:Outreach,
                        heading:'No PBNs',
                        paragraph:"We only publish white hat guest posts on real sites since Google hates poor SEO guest blogging. Serve Vision's guest blogging services never use shady tactics."
                    },
                    {
                        image:CustomerSatisfaction,
                        heading:'100% Customer Satisfaction',
                        paragraph:"We Guarantee Long Life of the Links We Acquire for You. Our effort doesn't finish when we write a guest article for you on a blog. Your link will be active for at least a year thanks to our guest posting service."
                    }
                ]
            }
        ],
        WhyServe:[
            {
                heading:'Why Serve Vision For Guest Post',
                paragraphs:[
                    {
                        paragraph:'Our niche-specific, influential guest posting service is expertly crafted to help your business become visible, authoritative, and well-recognized on websites with high visitor volumes.'
                    },
                    {
                        paragraph:'Unlike some companies, we don’t advertise our guest blogging services as a quick fix to improve your search engine rankings and build brand authority. We believe in pursuing the difficult path in order to provide data-driven results that are durable and advantageous for your long-term business success.'
                    },
                    {
                        paragraph:'Unlike some companies, we don’t advertise our guest blogging services as a quick fix to improve your search engine rankings and build brand authority. We believe in pursuing the difficult path in order to provide data-driven results that are durable and advantageous for your long-term business success.'
                    }
                ]
            }
        ],
        title:'Why Guest Posting Service',
        description:'Don’t let your competitors win the digital world while you struggle with outdated strategies. Let us be a partner in your success with our guest blog posting service that can turn into a fantastic flick for your digital glory with the best results.',
        WhyService:[
            {
                title:'Why Guest Row 1',
                service:[
                    {
                        heading:'Boost Your Niche Authority',
                        paragraph:'Our guest posting services collaborate with well-known bloggers in your industry-relevant field, and publishing a high-caliber piece helps to establish your brand as a market leader.'
                    },
                    {
                        heading:'Boost Your Niche Authority',
                        paragraph:'Our guest posting services collaborate with well-known bloggers in your industry-relevant field, and publishing a high-caliber piece helps to establish your brand as a market leader.'
                    },
                    {
                        heading:'Boost Your Niche Authority',
                        paragraph:'Our guest posting services collaborate with well-known bloggers in your industry-relevant field, and publishing a high-caliber piece helps to establish your brand as a market leader.'
                    }
                ]
            },
            {
                title:'Why Guest Row 2',
                service:[
                    {
                        heading:'Boost Your Niche Authority',
                        paragraph:'Our guest posting services collaborate with well-known bloggers in your industry-relevant field, and publishing a high-caliber piece helps to establish your brand as a market leader.'
                    },
                    {
                        heading:'Boost Your Niche Authority',
                        paragraph:'Our guest posting services collaborate with well-known bloggers in your industry-relevant field, and publishing a high-caliber piece helps to establish your brand as a market leader.'
                    },
                    {
                        heading:'Boost Your Niche Authority',
                        paragraph:'Our guest posting services collaborate with well-known bloggers in your industry-relevant field, and publishing a high-caliber piece helps to establish your brand as a market leader.'
                    }
                ]
            }
        ],
        BuyService:[
            {
                heading:'Buy Guest Posts Now',
                paragraph:'Place Your Order and Save 20% OFF on First Job!'
            }
        ],
        Frequently:[
                {
                    id:'0',
                    ques:'What is Guest Post Service ?',
                    answer:'A guest post is content you write on another website that links to yours. This increases your site’s awareness, qualified visitors, authority, credibility, and recognition'
                },
                {
                    id:'1',
                    ques:'Where Can I Guest Post ?',
                    answer:'Most blogs accept guest posts, but you need niche-relevant sites with excellent traffic and authority. Guest-posting websites have a ‘Write for Us’ page containing the blogger’s requirements.'
                }
        ]
    }
]
export const Website = [
    {
        punchLine:'100% unique and high-quality guest post writing services. We have the best team of content writers',
        keypointsSection:[
            {
                heading:`Our Authority Guest Posting Service is Custom`,
                keypoints:[
                    {
                        keypoint:'We provide quality posts that build your reputation as an expert ',
                    },
                    {
                        keypoint:'Our service enables you to expand your audience',
                    },
                    {
                        keypoint:'We provide quality backlinks and improved follower growth.'
                    }
                ],
                image:GuestPostService,
            }
        ],
        Services:[
            {
                title:'Guess Post Row 1',
                services:[
                    {
                        image:Guest,
                        heading:'Top-Rated Sites',
                        paragraph:"Not every site makes our Power Squad — we choose sites using a 15-point criterion. We'll show you our work: Not only do we examine domain authority, but also organic traffic flow, site appearance, domain life, indexed pages, traffic location, etc."
                    },
                    {
                        image:Outreach,
                        heading:'Contextual Links',
                        paragraph:"Just Good Links Surrounded by Good Stuff — We realise the importance of link placement, thus we don't position your link arbitrarily in the content, sidebars, or author-bio.The mix of in-content, contextual links, niche-specific blogger sites, and compelling, relevant material gives your website visibility, organic traffic, authority, and trustworthiness with one flick."
                    },
                    {
                        image:ContentQuality,
                        heading:'Content Quality',
                        paragraph:"We don't develop stuff merely for link placement. 'Content is King' is something we live by. We won't create digital clutter. We only give authoritative guest posting services, therefore from article inspiration to creation, we keep to quality."
                    }
                ]
            },
            {
                title:'Guess Post Row 2',
                services:[
                    {
                        image:Guest,
                        heading:'Native content writers',
                        paragraph:"Combine experience, professionalism, and originality – we know high-quality guest blogging services are powered by exceptional content writers with a sense for innovation. We do!Our clients obtain guest post service from us because we've created an in-house staff of hand-picked content writers that are seasoned, niche-experts, and know how to create Google- and reader-friendly guest articles."
                    },
                    {
                        image:Outreach,
                        heading:'No PBNs',
                        paragraph:"We only publish white hat guest posts on real sites since Google hates poor SEO guest blogging. Serve Vision's guest blogging services never use shady tactics."
                    },
                    {
                        image:CustomerSatisfaction,
                        heading:'100% Customer Satisfaction',
                        paragraph:"We Guarantee Long Life of the Links We Acquire for You. Our effort doesn't finish when we write a guest article for you on a blog. Your link will be active for at least a year thanks to our guest posting service."
                    }
                ]
            }
        ],
        WhyServe:[
            {
                heading:'Why Serve Vision For Guest Post',
                paragraphs:[
                    {
                        paragraph:'Our niche-specific, influential guest posting service is expertly crafted to help your business become visible, authoritative, and well-recognized on websites with high visitor volumes.'
                    },
                    {
                        paragraph:'Unlike some companies, we don’t advertise our guest blogging services as a quick fix to improve your search engine rankings and build brand authority. We believe in pursuing the difficult path in order to provide data-driven results that are durable and advantageous for your long-term business success.'
                    },
                    {
                        paragraph:'Unlike some companies, we don’t advertise our guest blogging services as a quick fix to improve your search engine rankings and build brand authority. We believe in pursuing the difficult path in order to provide data-driven results that are durable and advantageous for your long-term business success.'
                    }
                ]
            }
        ],
        title:'Why Guest Posting Service',
        description:'Don’t let your competitors win the digital world while you struggle with outdated strategies. Let us be a partner in your success with our guest blog posting service that can turn into a fantastic flick for your digital glory with the best results.',
        WhyService:[
            {
                title:'Why Guest Row 1',
                service:[
                    {
                        heading:'Boost Your Niche Authority',
                        paragraph:'Our guest posting services collaborate with well-known bloggers in your industry-relevant field, and publishing a high-caliber piece helps to establish your brand as a market leader.'
                    },
                    {
                        heading:'Boost Your Niche Authority',
                        paragraph:'Our guest posting services collaborate with well-known bloggers in your industry-relevant field, and publishing a high-caliber piece helps to establish your brand as a market leader.'
                    },
                    {
                        heading:'Boost Your Niche Authority',
                        paragraph:'Our guest posting services collaborate with well-known bloggers in your industry-relevant field, and publishing a high-caliber piece helps to establish your brand as a market leader.'
                    }
                ]
            },
            {
                title:'Why Guest Row 2',
                service:[
                    {
                        heading:'Boost Your Niche Authority',
                        paragraph:'Our guest posting services collaborate with well-known bloggers in your industry-relevant field, and publishing a high-caliber piece helps to establish your brand as a market leader.'
                    },
                    {
                        heading:'Boost Your Niche Authority',
                        paragraph:'Our guest posting services collaborate with well-known bloggers in your industry-relevant field, and publishing a high-caliber piece helps to establish your brand as a market leader.'
                    },
                    {
                        heading:'Boost Your Niche Authority',
                        paragraph:'Our guest posting services collaborate with well-known bloggers in your industry-relevant field, and publishing a high-caliber piece helps to establish your brand as a market leader.'
                    }
                ]
            }
        ],
        BuyService:[
            {
                heading:'Buy Guest Posts Now',
                paragraph:'Place Your Order and Save 20% OFF on First Job!'
            }
        ],
        Frequently:[
                {
                    id:'0',
                    ques:'What is Guest Post Service ?',
                    answer:'A guest post is content you write on another website that links to yours. This increases your site’s awareness, qualified visitors, authority, credibility, and recognition'
                },
                {
                    id:'1',
                    ques:'Where Can I Guest Post ?',
                    answer:'Most blogs accept guest posts, but you need niche-relevant sites with excellent traffic and authority. Guest-posting websites have a ‘Write for Us’ page containing the blogger’s requirements.'
                }
        ]
    }
]


                        